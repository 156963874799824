import './project.css';
import { Link } from 'react-router-dom';
export default function Project(props) {
    return (
        <div>
                <Link to={`/project/${props.title}`}>
                <div className="project-image">
                <img src={`${props.title}/logo.png`} alt="Project image"></img>
                    </div>
                    <div className="project-info">
                        <div className="project-title">
                            <Link to={`/project/${props.title}`}>
                                <h2>{props.title}</h2>
                            </Link>
                        </div>
                        <div className="project-description">
                            <p>{props.shortDescription}</p>
                        </div>
                        <div className="project-date">
                            <p>{props.date}</p>
                        </div>
                    </div>
                </Link>
            </div>
    )
}