import './info.css'
import Card from '../Card/card.module.js'
import ContactButtons from '../ContactButtons/contactButtons.js'
const InfoBox = () => {
    return (
        <div className="info-box">
            <section id="info-text">
                <p>Hello! I'm <strong>Atte Räisänen</strong> and I'm studying at Metropolia University of Applied Sciences to become a software developer. I have been passionate about computers, programming and everything technology related for a long time now and I'm always on the lookout for ways to take on new challenges and learn new skills. Below is a list of some of the projects I've worked on.</p>
                <ContactButtons />
            </section>
            <section id="info-card">
                <Card />
            </section>
        </div>
    )
}

export default InfoBox