import projectData from './projects.json'
export const getProjects = async () => {
    try {
        return projectData;
    } catch (error) {
        console.log(error)
    }
}

export const getProjectByTitle = async (title) => {
    try {
        console.log(projectData);
        const project = projectData.find((project) => project.title === title);
        console.log(project.title);
        return project;
    } catch (error) {
        console.log(error)
    }
}

