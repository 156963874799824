import './projects.css'
import Project from './Project/project.module.js';
import { useState, useEffect } from 'react';
import { getProjects } from '../../lib/tools.js';
const Projects = () => {
    const [projects, setProjects] = useState([])
    useEffect(() => {
        fetchProjects();
    }, [])

    const fetchProjects = async () => {
        const data = await getProjects();
        setProjects(data);
    }

        console.log(projects)
    return (
        <div className='projects-container'>
            {projects.length > 0 ? projects.map((project, index) => (
                <div className='project' style={{ animationDelay: `${100 * index}ms` }}>
                    <Project key={index}
                image={project.image}
                title={project.title}
                shortDescription={project.shortDescription}
                date={project.date} />
                </div>
            )
            ) : 'Loading...'}
        </div>
    )
}

export default Projects