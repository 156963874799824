import './skills.css'
import Skill from './Skill/skill.module.js';
const Projects = () => {
    return (
        <div className='skills-container'>
            <Skill skillText="HTML" />
            <Skill skillText="CSS" />
            <Skill skillText="JavaScript" />
            <Skill skillText="React" />
            <Skill skillText="Python" />
            <Skill skillText="Java" />
            <Skill skillText="C#" />
        </div>
    )
}

export default Projects