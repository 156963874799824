import style from './navbar.css'
import { Link } from 'react-router-dom'
const NavBar = () => {
    return (
        <div>
            <Link to="/">
                <img src={process.env.PUBLIC_URL + "/logo.png"} className="logo"></img>
            </Link>
        </div>
    )
}



export default NavBar