import './card.css';
import Skills from '../Skills/skills.module.js';
const Card = () => {
    return (
        <div className="card-container">
            <div className="card">
            <img src="./kuva.jpeg" alt="Avatar" className="card-img"></img>
                <div className="card-info">
                    <h2 className="card-name">Atte Räisänen</h2>
                    <p className="city-name">Vantaa</p>
                    <p className="school-name">Student at Metropolia UAS</p>
                </div>
                <div className="card-skills">
                    <Skills />
                </div>
            </div>
        </div>
    );
    }
    export default Card;