import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getProjectByTitle } from '../../../lib/tools.js';
import './projectdetailed.css';

const ProjectImages = (props) => {
  let imagesAmount = props.imagesAmount;
  let additionalMedia = props.additionalMedia;
  let images = [];
  if (additionalMedia != null) {
    for(let i = 0; i < additionalMedia.length; i++) {
      images.push(
        <img src={`${process.env.PUBLIC_URL}/${props.title}/img/${additionalMedia[i]}`} alt={props.title} />
      )
    }
  }
  for (let i = 0; i < imagesAmount; i++) {
    images.push(
      <img src={`${process.env.PUBLIC_URL}/${props.title}/img/img${i+1}.png`} alt={props.title} />
      )
  }
  return (
    <div class="project-detailed-images">
      {images}
    </div>
  )
}

function ProjectDetailed() {
  const { title } = useParams();
  const [project, setProject] = useState([]);
  useEffect(() => {
    fetchProject();
  }, [])

  const fetchProject = async () => {
    const data = await getProjectByTitle(title);
    setProject(data);
  }

  console.log(project.title)
  return (
    <div>
      {
        project === undefined ? <h1>Project not found</h1>
        :
        <div class="project-detailed-container">
          <img  class="project-detailed-img" src={`${process.env.PUBLIC_URL}/${project.title}/logo.png`} alt={project.title} />
          <div class="project-detailed-title">
            <h2>{project.title}</h2>
            <p class="project-detailed-date">{project.date}</p>
            <a href={project.link != null ? project.link : ""}>View source</a>
          </div>
          <p class="project-detailed-description">{project.longDescription}</p>
          <ProjectImages imagesAmount={project.images} additionalMedia={project.additionalMedia} title={project.title} />
        </div> 
      }
    </div>
  );
}

export default ProjectDetailed;
