import './contactButtons.css'
import { Link } from 'react-router-dom'
const ContactButtons = () => {
    return (
        <div className="contact-buttons">
            <Link to="https://www.linkedin.com/in/atte-r%C3%A4is%C3%A4nen-2b3195205/">
                <div className="contact-button">
                    <img src="linkedin.png" className="contact-button-img"></img>
                    <p>LinkedIn</p>
                </div>
            </Link>
            <Link to="https://github.com/atteraisanen">
                <div className="contact-button">
                    <img src="github.png" className="contact-button-img"></img>
                    <p>GitHub</p>
                </div>
            </Link>
            <div className="contact-button email-contact">
                <img src="email.png" className="contact-button-img"></img>
                <a>atteraisa@gmail.com</a>
            </div>
        </div>
    )
}

export default ContactButtons;