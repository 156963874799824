import logo from './logo.svg';
import './styling/main.css';
import NavBar from './components/Navbar/navbar.module.js';
import MainPage from './components/Main/mainPage.js';
import ProjectDetailed from './components/Projects/ProjectDetailed/projectdetailed.module.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content">
          <NavBar />
          <Routes>
            <Route path="/project/:title" element={<ProjectDetailed />} />
            <Route path="/" element={<MainPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
