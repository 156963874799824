import InfoBox from "../Info/info.module"
import Projects from "../Projects/projects.module"
const MainPage = () => {
    return (
        <div>
            <InfoBox />
            <Projects />
        </div>
    )
}

export default MainPage